/* GLOBAL STYLES ------------------------------------------------ */
body {
  background-color: #bbb;
}
#page { box-sizing: border-box; width: 100%; max-width: 1200px; padding: 50px; margin: 0 auto; background-color: #fff; color: #000; font-family: Arial, sans-serif; font-size: 16px; }
button { display: inline-block; border: none; padding: 10px 20px; margin: 0; text-decoration: none; background: #ccc; color: #ffffff; font-family: Arial, sans-serif; font-size: 1em; cursor: pointer; text-align: center; -webkit-appearance: none;-moz-appearance: none;
  -webkit-transition: background-color 200ms ease-in-out;
  -o-transition: background-color 200ms ease-in-out;
  -moz-transition: background-color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out;
}
button:hover { background-color: #aaa;
  -webkit-transition: background-color 300ms ease-in-out;
  -o-transition: background-color 300ms ease-in-out;
  -moz-transition: background-color 300ms ease-in-out;
  transition: background-color 300ms ease-in-out;
}
